import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import "./css/header.scss"
import Logo from "../../images/logo.svg"

const Header = ({ children, textHeader }) => (
  <header className={textHeader?"text-header":""}>
      <div className="container">
          <div className="main-header row">
              <div className="col-xs-12 col-sm-12 col-lg-6 text-center">
                  <Link to="/">
                      <div className="logo">
                          <img src={Logo} alt="QueroPedir" title="QueroPedir" />
                      </div>
                  </Link>
              </div>
              <div className="col-xs col-sm">
                  <div id="menu">
                      <input id="toggle-menu" type="checkbox"></input>
                      <label htmlFor="toggle-menu" className="hamburger">
                          <div className="top-bun"></div>
                          <div className="meat"></div>
                          <div className="bottom-bun"></div>
                      </label>
                      <div className="nav float-right">
                          <nav>
                              <Link to="/">Início</Link>
                              <Link to="/parceiros">Cadastre seu Estabelecimento</Link>
                              <Link to="/contato">Contato</Link>
                          </nav>
                      </div>
                  </div>
              </div>
          </div>
          <div className="row">{children}</div>
      </div>
  </header>
)

Header.propTypes = {
  textHeader: PropTypes.bool,
  children: PropTypes.node.isRequired
}

Header.defaultProps = {
  textHeader: true,
}

export default Header
