import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import "./css/footer.scss"
import Logo from "../../images/logo.svg"

const Footer = () => (
  <footer>
    <div className="container">
      <div className="row main-footer">
        <div className="col-md">
          <img src={Logo} alt="QueroPedir" title="QueroPedir" />
        </div>
        <div className="col-md">
          <h4>Saiba Mais</h4>
          <Link to="/">Início</Link>
          <Link to="/parceiros">Cadastre seu Estabelecimento</Link>
          <Link to="/contato">Contato</Link>
        </div>
        <div className="col-md">
          <h4>Encontre-nos</h4>
          <a href="https://www.facebook.com/queropedirapp" target="_BLANK">Facebook</a>
          <a href="https://www.instagram.com/queropedirapp" target="_BLANK">Instagram</a>
        </div>
      </div>
    </div>
    <div className="copyright-footer">
      <p className="col-md text-center">
        © {new Date().getFullYear()}, <strong>QueroPedir</strong> é um aplicativo da empresa
        {` `}
        <a href="https://www.systemcode.com.br" target="_BLANK">System Code</a>.
      </p>
    </div>
  </footer>
)

export default Footer
